import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import CreateNavbar from '../components/Navbar/Navbar';
import checkIcon from '../assets/img/Comparison/checkIcon.png';
import Journey from '../assets/img/Contact/standard.svg';
import premium from '../assets/img/Contact/premium.svg';
import { useNavigate } from 'react-router-dom';
import { JourneyStatusCheck } from '../api/JourneyStatusCheck';
import { SectionWiseInsertion } from '../api/SectionWiseInsertion';
import { fetchUserDetails } from '../api/UserMetadata';

const Comparison: React.FC = () => {
  const navigate = useNavigate();
  const [activeSectionIndex, setActiveSectionIndex] = useState<number | null>(null);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [draftData, setDraftData] = useState<any>(null);
  const [searchLogs, setSearchLogs] = useState<any[]>([]);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [backendMessage, setBackendMessage] = useState("");

  // Load data from localStorage on component mount
  useEffect(() => {
    const storedActiveSectionIndex = localStorage.getItem("activeSectionIndex");
    const storedSelectedFile = localStorage.getItem("selectedFile");
    const storedDraftData = localStorage.getItem("draftData");
    const storedSearchLogs = localStorage.getItem("searchLogs");

    if (storedActiveSectionIndex) {
      setActiveSectionIndex(JSON.parse(storedActiveSectionIndex));
    }
    if (storedSelectedFile) {
      setSelectedFile(JSON.parse(storedSelectedFile));
    }
    if (storedDraftData) {
      setDraftData(JSON.parse(storedDraftData));
    }
    if (storedSearchLogs) {
      setSearchLogs(JSON.parse(storedSearchLogs));
    }
  }, []);

  const handleStandardJourneyClick = async () => {
    try {
      const useremail = localStorage.getItem("Email");
      if (!useremail) {
        setIsSnackbarOpen(true);
        setBackendMessage("User email is required to generate the journey.");
        return;
      }

      const journeyCheck = await JourneyStatusCheck();
      if (journeyCheck?.journey_status === "initiated") {
        setIsSnackbarOpen(true);
        setBackendMessage("A journey is already under creation. Please wait for it to complete.");
        return;
      }

      if (!activeSectionIndex || !draftData || searchLogs.length === 0) {
        setIsSnackbarOpen(true);
        setBackendMessage("Missing required data to initiate the journey.");
        return;
      }

      const response = await SectionWiseInsertion(activeSectionIndex, selectedFile, draftData, searchLogs, true);

      if (response.ok) {
        navigate("/myjourney", { state: { redirect: "Questionnaire-to-MyJourney" } });
      } else {
        const responseData = await response.json();
        setIsSnackbarOpen(true);
        setBackendMessage(responseData.message || "Failed to initiate journey.");
      }
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : "An unexpected error occurred.";
      setIsSnackbarOpen(true);
      setBackendMessage(errorMessage);
    }
  };

  const handlePremiumJourneyClick = async () => {
    try {
      const useremail = localStorage.getItem("Email");
      if (!useremail) throw new Error("User email is required to generate the journey.");
  
      const userDetails = await fetchUserDetails(useremail, navigate);
      if (userDetails?.StandardJourney?.version === 0) {
        if (!activeSectionIndex || !draftData || searchLogs.length === 0) {
          throw new Error("Missing required data to initiate the journey.");
        }
        const standardJourneyResponse = await SectionWiseInsertion(activeSectionIndex,selectedFile,draftData,searchLogs,true);
        if (!standardJourneyResponse.ok) {
          const { message } = await standardJourneyResponse.json();
          throw new Error(message || "Failed to initiate standard journey.");
        }
      }
      navigate("/premiumAdvantages");
    } catch (error) {
      setIsSnackbarOpen(true);
      setBackendMessage(error instanceof Error ? error.message : "An unexpected error occurred.");
    }
  };  

  return (
    <div className="min-h-screen bg-white">
      <div className="top-0 left-0 right-0 z-20">
        <CreateNavbar page="home" />
      </div>
      <div className="flex flex-col items-center mt-5">
        <h2 className="text-lg md:text-3xl font-semibold text-back-prev-text mb-4 px-2 text-center">
          Find The Best Plan For Your Personalised Career Journey
        </h2>
        <div className="w-full max-w-4xl px-4 md:p-4">
          <div className=" border "style={{ boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)' }}>
            <table className="w-full bg-white text-center">
              <thead>
              <tr>
                <th className="px-1 md:px-4 py-2 border-b border-[#cccccc] text-xs md:text-base"></th>
                <th className="px-1 md:px-4 py-2 border-b border-[#cccccc] text-gray-700 font-bold text-xs md:text-base">
                  <div className="flex flex-col items-center">
                    <img src={Journey} alt="Standard Journey" className="w-4 md:w-8 mb-2" />
                    <span>Standard Journey</span>
                  </div>
                </th>
                <th
                  className="px-1 md:px-4 py-2 font-bold text-back-prev-text text-xs md:text-base border-l-2 border-r-2"
                  style={{ borderBottom: '1px solid #cccccc', borderTop: '2px solid #FCB017', borderColor: '#FCB017' }}
                >
                  <div className="flex flex-col items-center">
                    <img src={premium} alt="Premium Journey" className="w-4 md:w-8 mb-2" />
                    <span>Premium Journey</span>
                  </div>
                </th>
              </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="px-1 md:px-4 py-1 md:py-2 border-b border-[#cccccc] text-xs md:text-base">Yearly Actionable Plan</td>
                  <td className="px-1 md:px-4 py-1 md:py-2 border-b border-[#cccccc]">
                    <img src={checkIcon} alt="Check Icon" className="w-3 md:w-5 h-3 md:h-5 mx-auto" />
                  </td>
                  <td
                    className="px-1 md:px-4 py-1 md:py-2 border-l-2 border-r-2"
                    style={{ borderBottom: '1px solid #cccccc', borderColor: '#FCB017' }}>
                    <img src={checkIcon} alt="Check Icon" className="w-3 md:w-5 h-3 md:h-5 mx-auto" />
                  </td>
                </tr>
                <tr>
                  <td className="px-1 md:px-4 py-1 md:py-2 border-b border-[#cccccc] text-xs md:text-base">Qtr. Actionable Plan</td>
                  <td className="px-1 md:px-4 py-1 md:py-2 border-b border-[#cccccc] text-xs md:text-base">—</td>
                  <td
                    className="px-1 md:px-4 py-1 md:py-2 border-l-2 border-r-2"
                    style={{ borderBottom: '1px solid #cccccc', borderColor: '#FCB017' }}>
                    <img src={checkIcon} alt="Check Icon" className="w-3 md:w-5 h-3 md:h-5 mx-auto" />
                  </td>
                </tr>
                <tr>
                  <td className="px-1 md:px-4 py-1 md:py-2 border-b border-[#cccccc] text-xs md:text-base">Alt. Career Journeys</td>
                  <td className="px-1 md:px-4 py-1 md:py-2 border-b border-[#cccccc]">
                    <img src={checkIcon} alt="Check Icon" className="w-3 md:w-5 h-3 md:h-5 mx-auto" />
                  </td>
                  <td
                    className="px-1 md:px-4 py-1 md:py-2 border-l-2 border-r-2"
                    style={{ borderBottom: '1px solid #cccccc', borderColor: '#FCB017' }}>
                    <img src={checkIcon} alt="Check Icon" className="w-3 md:w-5 h-3 md:h-5 mx-auto" />
                  </td>
                </tr>
                <tr>
                  <td className="px-1 md:px-4 py-1 md:py-2 border-b border-[#cccccc] text-xs md:text-base">Profile Analysis</td>
                  <td className="px-1 md:px-4 py-1 md:py-2 border-b border-[#cccccc] text-xs md:text-base">—</td>
                  <td
                    className="px-1 md:px-4 py-1 md:py-2 border-l-2 border-r-2"
                    style={{ borderBottom: '1px solid #cccccc', borderColor: '#FCB017' }}>
                    <img src={checkIcon} alt="Check Icon" className="w-3 md:w-5 h-3 md:h-5 mx-auto" />
                  </td>
                </tr>
                <tr>
                  <td className="px-1 md:px-4 py-1 md:py-2 border-b border-[#cccccc] text-xs md:text-base">NorthStar</td>
                  <td className="px-1 md:px-4 py-1 md:py-2 border-b border-[#cccccc] text-xs md:text-base">—</td>
                  <td
                    className="px-1 md:px-4 py-1 md:py-2 border-l-2 border-r-2"
                    style={{ borderBottom: '1px solid #cccccc', borderColor: '#FCB017' }}>
                    <img src={checkIcon} alt="Check Icon" className="w-3 md:w-5 h-3 md:h-5 mx-auto" />
                  </td>
                </tr>
                <tr>
                  <td className="px-1 md:px-4 py-1 md:py-2 border-b border-[#cccccc] text-xs md:text-base">Industry Trends *</td>
                  <td className="px-1 md:px-4 py-1 md:py-2 border-b border-[#cccccc] text-xs md:text-base">—</td>
                  <td
                    className="px-1 md:px-4 py-1 md:py-2 border-l-2 border-r-2"
                    style={{ borderBottom: '1px solid #cccccc', borderColor: '#75916E' }}>
                    <img src={checkIcon} alt="Check Icon" className="w-3 md:w-5 h-3 md:h-5 mx-auto" />
                  </td>
                </tr>
                <tr>
                  <td className="px-1 md:px-4 py-1 md:py-2 border-b border-[#cccccc] text-xs md:text-base">Growth Opportunities</td>
                  <td className="px-1 md:px-4 py-1 md:py-2 border-b border-[#cccccc] text-xs md:text-base">—</td>
                  <td
                    className="px-1 md:px-4 py-1 md:py-2 border-l-2 border-r-2"
                    style={{ borderBottom: '1px solid #cccccc', borderColor: '#0778B6' }}
                  >
                    <img src={checkIcon} alt="Check Icon" className="w-3 md:w-5 h-3 md:h-5 mx-auto" />
                  </td>
                </tr>
                <tr>
                  <td className="px-1 md:px-4 py-1 md:py-2 border-b border-[#cccccc] text-xs md:text-base">Summary</td>
                  <td className="px-1 md:px-4 py-1 md:py-2 border-b border-[#cccccc] text-xs md:text-base">—</td>
                  <td
                    className="px-1 md:px-4 py-1 md:py-2 border-l-2 border-r-2"
                    style={{ borderBottom: '1px solid #cccccc', borderColor: '#0778B6' }}
                  >
                    <img src={checkIcon} alt="Check Icon" className="w-3 md:w-5 h-3 md:h-5 mx-auto" />
                  </td>
                </tr>
                <tr>
                  <td className="px-1 md:px-4 py-1 md:py-2 border-b border-[#cccccc] text-xs md:text-base">
                    Job Matching <span className="text-[10px] md:text-xs">(Coming Soon)</span>
                  </td>
                  <td className="px-1 md:px-4 py-1 md:py-2 border-b border-[#cccccc] text-xs md:text-base">—</td>
                  <td
                    className="px-1 md:px-4 py-1 md:py-2 border-l-2 border-r-2"
                    style={{ borderBottom: '1px solid #cccccc', borderColor: '#0778B6' }}>                    
                  <img src={checkIcon} alt="Check Icon" className="w-3 md:w-5 h-3 md:h-5 mx-auto" />
                  </td>
                </tr>
                <tr>
                  <td className="px-1 md:px-4 py-1 md:py-2 border-b border-[#cccccc] text-xs md:text-base">
                    Journey Tracking <span className="text-[10px] md:text-xs"><br />(Coming Soon)</span>
                  </td>
                  <td className="px-1 md:px-4 py-1 md:py-2 border-b border-[#cccccc] text-xs md:text-base">—</td>
                  <td
                    className="px-1 md:px-4 py-1 md:py-2 border-l-2 border-r-2"
                    style={{ borderBottom: '1px solid #cccccc', borderColor: '#0778B6' }}>
                    <img src={checkIcon} alt="Check Icon" className="w-3 md:w-5 h-3 md:h-5 mx-auto" />
                  </td>
                </tr>
                <tr>
                  <td className="px-1 md:px-4 py-1 md:py-2 border-b border-[#cccccc] text-xs md:text-base">Report Pages</td>
                  <td className="px-1 md:px-4 py-1 md:py-2 border-b border-[#cccccc] text-xs md:text-base">Two - Three</td>
                  <td
                    className="px-1 md:px-4 py-1 md:py-2 border-l-2 border-r-2 text-xs md:text-base"
                    style={{ borderBottom: '1px solid  #cccccc', borderColor: '#0778B6' }}
                  >
                    Ten - Twelve
                  </td>
                </tr>
                <tr>
                  <td className="px-1 md:px-4 py-1 md:py-2 border-b border-[#cccccc] text-xs md:text-base">Pricing</td>
                  <td className="px-1 md:px-4 py-1 md:py-2 border-b border-[#cccccc] font-semibold text-xs md:text-base">Free</td>
                  <td className="px-1 md:px-4 py-1 md:py-2 font-semibold text-back-prev-text border-l-2 border-r-2 text-xs md:text-base"style={{ borderBottom: '1px solid  #cccccc', borderColor: '#0778B6' }}>
                    <span className="line-through text-black">₹1199</span> ₹199/-
                  </td>
                </tr>
                <tr>
                  <td className="px-1 md:px-4 py-2 md:py-4"></td>
                  <td className="px-1 md:px-4 py-2 md:py-4">
                    <button
                      className="bg-custom-orange text-white px-2 md:px-6 py-1 md:py-2 rounded-full font-semibold text-xs md:text-base"
                      onClick={handleStandardJourneyClick}
                    >
                      Standard Journey
                    </button>
                  </td>
                  <td className="px-1 md:px-4 py-2 md:py-4 border-l-2 border-r-2 "
                  style={{ borderBottom: '2px solid  #0778B6', borderColor: '#0778B6' }}>
                    <button className="bg-secondary text-white px-2 md:px-6 py-1 md:py-2 rounded-full font-semibold text-xs md:text-base"
                    onClick={handlePremiumJourneyClick}>
                      Premium Journey
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Comparison;