import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Landing from './Page/Landing';
import Login from "./Page/Login";
import Questionnaire from './Page/Questionnaire';
import Contact from './Page/Contact';
import Home from './Page/Home';
import Shipping from './Page/Shipping';
import Cancellation from './Page/Cancellation';
import Privacy from './Page/Privacy';
import Terms from './Page/Terms';
import BasicJourney from './Page/BasicJourney';
import Journey from './Page/JourneyLoading';
import About from './Page/About';
import Explore from './Page/ExploreOpportunities';
import PremiumJourney from './Page/PremiumJourney';
import Comingsoon from './Page/ComingSoon';
import NotFound from './Page/NotFound';
import TrainingPage from './components/trainings/TrainingPage';
import TrainingsTemp from './components/trainings/TrainingsTemplate';
import SuccessPage from './components/trainings/PaymentSuccess';
import LoginPage from './components/Counsellor/counsellorLoginPage';
import CounselorsHome from './components/CounsellorUser/counsellorUserHome';
import BookSessionPage from './components/CounsellorUser/BookSession';
import BookedSessionsPage from './components/CounsellorUser/BookedSession';
import DetailedSessionPage from './components/CounsellorUser/SessionsDetails/DetailedSessionPage';
import CounselorBookedSessionsPage from './components/Counsellor/counsellorDashboard';
import Technical from './Page/TechnicalSupport';
import PremiumJourneyInterim from './Page/PremiumInterimPage';
import PremiumAdvantage from './components/common/PremiumAdvantage';
import PremiumJourneyAdmin from './Page/PremiumJourneyAdmin';
import UserPremiumJourney from './components/Counsellor/userPremiumJourney';
import StandardJourney from './components/Counsellor/userStandardJourney';
import BasicJourneyAdmin from './Page/UserBasicJourney';
import CounsellorsClientPortFolio from './Page/CounsellorsClientPortfolio';
import CounsellorProtectedRoute from './middleware/counsellorProtectedRoute';
import CertificateMainPage from './Page/CertificatePage';
import Jobs from './components/jobs/jobsPage';
import { LogoutMethod } from './components/Navbar/Navbar';
import Comparison from './Page/Comparison';

function App() {

  return (
    <Router>
      <div className="App overflow-hidden">
        <Routes>
          <Route path="/"  element={<Landing/>} />
          < Route path="/login" element={<Login clientId={''}/>} />
          <Route path="/home"  element={<Home/>} />
          <Route path="/questionnaire"  element={<Questionnaire/>} />
          <Route path="/myjourney"  element={<BasicJourney/>} />
          <Route path="/technical"  element={<Technical/>} />
          <Route path="/contactUs"  element={<Contact/>} />
          <Route path="/shipping"  element={<Shipping/>} />
          <Route path="/cancellation&refund"  element={<Cancellation/>} />
          <Route path="/privacypolicy"  element={<Privacy/>} />
          <Route path="/terms&conditions"  element={<Terms/>} />
          <Route path="/journey"  element={<Journey/>} />
          <Route path="/premiumAdvantages"  element={<PremiumAdvantage/>} />
          <Route path="/premiumjourneyloading"  element={<PremiumJourneyInterim />} />
          <Route path="/premiumjourney"  element={<PremiumJourney/>} />
          <Route path="/PremiumJourneyAdmin"  element={<PremiumJourneyAdmin/>} />
          <Route path="/StandardJourneyAdmin"  element={<BasicJourneyAdmin/>} />
          <Route path="/about"  element={<About/>} />
          <Route path="/logout"  element={<LogoutMethod/>} />
          <Route path="/comingsoon"  element={<Comingsoon/>} />
          <Route path="/exploreopportunities"  element={<Explore/>} /> 
          {/*Training Routes Starts from Here */}
          <Route path="/trainings"  element={<TrainingPage/>} />
          <Route path="/trainings/:id" element={<TrainingsTemp />} />
          <Route path="/trainings/success" element={<SuccessPage />} />
          {/*Training Routes Ends Here */}
          {/*Counsellor Routes Starts from Here */}
          <Route path="/counsellor/login" element={<LoginPage />} />
          <Route path="/counsellor/dashboard" element={<CounsellorProtectedRoute element={<CounselorBookedSessionsPage />} />} />
          <Route path="/counsellor/userPortfolio" element={<CounsellorProtectedRoute element={<CounsellorsClientPortFolio />} />} />
          <Route path="/counsellor/userpremiumjourney" element={<CounsellorProtectedRoute element={<UserPremiumJourney />} />} />
          <Route path="/counsellor/userstandardjourney" element={<CounsellorProtectedRoute element={<StandardJourney />} />} />
          {/* open for councellor as well as general from this */}
          <Route path="/counselling" element={<CounselorsHome />} />
          <Route path="/book-session/:id" element={<BookSessionPage />} />
          <Route path="/booked-sessions" element={<BookedSessionsPage />} />
          <Route path="/sessions/:id" element={<DetailedSessionPage />} />
          {/*Jobs Routes Starts from Here */}
          <Route path="/jobs" element={<Jobs />} />
          <Route path="/verify/:id" element={<CertificateMainPage />} />
          <Route path="/comparison" element={<Comparison />} />
           {/* Catch-all route for undefined paths */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
