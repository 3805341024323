import React, { useEffect, useState } from 'react';
import CreateNavbar from '../components/Navbar/Navbar';
import { useLocation } from 'react-router-dom';

const NotFound: React.FC = () => {
  const location = useLocation();
  const { redirect } = location.state || "";
  const [ststusCode, setStatusCode] = useState(0);
  useEffect(() => {
    if (redirect === "Premium-journet-page-unauthorised") {
      setStatusCode(403);
        window.history.replaceState({}, "");
    }
}, [redirect]);
  return (
    <><CreateNavbar page='home' /><div>
      <style>
        {`
          .not-found {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100vh;
            background-color: #f8f9fa;
            text-align: center;
            padding: 20px;
          }

          .not-found-content {
            max-width: 600px;
            width: 100%;
            padding: 20px;
            background-color: white;
            border-radius: 8px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          }

          .not-found-title {
            font-size: 6rem;
            margin: 0;
            color: #343a40;
          }

          .not-found-message {
            font-size: 1.5rem;
            margin: 0;
            color: #6c757d;
          }

          .not-found-description {
            margin: 1rem 0;
            color: #495057;
          }

          .not-found-link {
            display: inline-block;
            margin-top: 1rem;
            font-size: 1rem;
            color: #007bff;
            text-decoration: none;
            border: 1px solid #007bff;
            padding: 10px 20px;
            border-radius: 4px;
            transition: background-color 0.2s, color 0.2s;
          }

          .not-found-link:hover {
            background-color: #007bff;
            color: white;
          }
        `}
      </style>
      <div className="not-found">
        <div className="not-found-content">
          <h1 className="not-found-title">{ststusCode ===403 ? 403: 404}</h1>
          <p className="not-found-message">{ststusCode ===403 ? "Forbidden" : "Page Not Found"}</p>
          <p className="not-found-description">{ststusCode ===403 ?"You are not authorized to perform this action." :"The page you are looking for does not exist."}</p>
          <a href="/" className="not-found-link">Go to Home</a>
        </div>
      </div>
    </div></>
  );
};

export default NotFound;
