import React from 'react';
import { useSpring, animated } from 'react-spring';

interface MovingTextBannerProps {
  text: string; // The text prop that you want to pass to the component
}

const MovingTextBanner: React.FC<MovingTextBannerProps> = ({ text }) => {
  const props = useSpring({
    to: { transform: 'translateX(-100%)' },
    from: { transform: 'translateX(100%)' },
    reset: true,
    reverse: false,
    config: { duration: 15000 }, // Adjust the speed
    loop: true,
  });
  const zIndex = true ? -1 : 10; // If other element is open, push the banner behind (z-index: -1)


  return (
    <div className="flex flex-row  pt-20 overflow-hidden">
      <animated.div style={{...props,zIndex:-1}} className="whitespace-nowrap text-[#0778B6] font-sans  text-xl py-2 italic">
        {text}
      </animated.div>
    </div>
  );
};

export default MovingTextBanner;
