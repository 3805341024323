import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogContentText, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import { addPreparationNotes, preparationNotesUpdate, viewPreparationNotes } from '../../../../api/CounsellourPrepNotes';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SwitchSelector from "react-switch-selector";
import { PaperComponent } from './feedbackPopup';

interface Note {
  timeStamp: string;
  notes: string;
}

interface PrepProps {
  currentSession: any;
  isPrepNotesOpen: boolean;
  setIsPrepNotesOpen: (isOpen: boolean) => void;
}

const PrepNotesPopup: React.FC<PrepProps> = ({ currentSession, isPrepNotesOpen, setIsPrepNotesOpen }) => {
  const navigate = useNavigate();
  const [prepNotes, setPrepNotes] = useState('');
  const [putMethod, setPutMethod] = useState(false);
  const [notes, setNotes] = useState<Note[]>([]);
  const [alignment, setAlignment] = useState('add');
  const [editNote, setEditNote] = useState<Note | null>(null);
  const [switchToggle, setSwitchToggle] = useState(0)

  const username = currentSession?.clientName || '';
  const counsellorEmail = currentSession?.counselor?.email || '';

  const options = [
    {
      label: "Add New",
      value: "add",
    },
    {
      label: "View Notes",
      value: "view",
    },
  ];

  const fetchInitialNotes = async () => {
    const viewBody = { username, counsellor_email: counsellorEmail };
    try {
      const data = await viewPreparationNotes(viewBody, navigate);
      if (data.username === currentSession?.clientName) {
        if (data.notes.length > 0) setPutMethod(true);
        const sortedNotes = [...data.notes].sort(
          (a, b) => parseInt(b.timeStamp) - parseInt(a.timeStamp)
        )
        setNotes(sortedNotes)
      }
    } catch (error) {
      console.error('Error fetching notes:', error);
    }
  };

  const saveNotes = async () => {
    const bodyData = { username, counsellor_email: counsellorEmail, notes };
    try {
      const response = putMethod
        ? await preparationNotesUpdate(bodyData, navigate)
        : await addPreparationNotes(bodyData, navigate);
      if (response.success) {
        alert(response.message);
        setIsPrepNotesOpen(false);
      }
    } catch (error) {
      console.error('Error saving notes:', error);
    }
  };

  useEffect(() => {
    if (currentSession) fetchInitialNotes();
  }, [currentSession]);

  const addOrUpdateNote = () => {
    if (!prepNotes.trim()) return
    let updatedNotes
    if (editNote) {
      updatedNotes = notes.map((note) =>
        note.timeStamp === editNote.timeStamp
          ? { ...note, notes: prepNotes }
          : note
      )
    } else {
      updatedNotes = [
        { timeStamp: Date.now().toString(), notes: prepNotes },
        ...notes,
      ]
    }

    setNotes(updatedNotes);
    setPrepNotes('');
    setEditNote(null);
    setAlignment('view');
    setSwitchToggle((prev) => prev + 1)
  };

  const deleteNote = (id: string) => {
    setNotes(notes.filter((note) => note.timeStamp !== id));
  };

  const clearNote = () => {
    setPrepNotes('');
    setEditNote(null);
  };

  const handleClose = () => {
    setIsPrepNotesOpen(false);
  };

  return (
    <Dialog
      open={isPrepNotesOpen}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      sx={{ '& .MuiDialog-paper': { width: '600px', height: '450px', position: 'relative' } }}
      maxWidth="md"
      fullWidth
    >
      <span className="text-xs font-light text-gray-500 ml-2">(You can drag this anywhere)</span>
      <div className="flex flex-col md:flex-row justify-between p-2 items-center text-xl font-semibold" id="draggable-dialog-title" style={{ cursor: 'move' }}>
        <div>My Preparation Notes</div>
        <div style={{ width: 200, height: 40 }}>
          <SwitchSelector
            key={switchToggle}
            onChange={(newValue: unknown) => setAlignment(newValue as string)}
            options={options}
            initialSelectedIndex={options.findIndex(option => option.value === alignment)}
            backgroundColor="#E1E3EA"
          />
        </div>
      </div>
      <div className="h-full">
        {alignment === 'add' || editNote ? (
          <div>
            <DialogContent sx={{ overflowY: 'auto', height: '310px' }}>
              <DialogContentText>
                <ReactQuill
                  value={prepNotes}
                  onChange={setPrepNotes}
                  className="h-52 w-full"
                  placeholder="Enter your notes here..."
                />
              </DialogContentText>
            </DialogContent>
            <div className="flex justify-between py-2 px-6">
              <button
                onClick={clearNote}
                className="px-4 py-2 bg-red-500 text-white font-semibold rounded-lg hover:bg-red-600 transition-colors duration-300"
              >
                Clear
              </button>
              <button
                onClick={addOrUpdateNote}
                className="px-4 py-2 bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-600 transition-colors duration-300"
              >
                {editNote ? 'Update' : 'Add'}
              </button>
            </div>
          </div>
        ) : (
          <div className="bg-gray-100 py-2 px-4 h-full flex flex-col">
            <h2 className="text-lg font-bold mb-2">Saved Notes ({notes.length})</h2>
            <div className="overflow-y-auto h-64">
              <div className="grid grid-cols-1 gap-2">
                {notes.map((note) => (
                  <div
                    key={note.timeStamp}
                    className="bg-gray-50 p-2 rounded-md border border-gray-200"
                  >
                    <div
                      className="text-gray-800 mb-2"
                      dangerouslySetInnerHTML={{ __html: note.notes }}
                    />
                    <div className="flex justify-end">
                      <IconButton
                        onClick={() => {
                          setPrepNotes(note.notes);
                          setEditNote(note);
                        }}
                        aria-label="edit"
                      >
                        <EditIcon fontSize="small" style={{ color: '#0778b6' }} />
                      </IconButton>
                      <IconButton
                        onClick={() => deleteNote(note.timeStamp)}
                        aria-label="delete"
                      >
                        <DeleteIcon fontSize="small" style={{ color: '#e5383b' }} />
                      </IconButton>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-col space-y-2 md:flex-row md:space-y-0 md:space-x-2 justify-between pt-5">
              <button
                onClick={saveNotes}
                className="px-4 py-2 bg-green-500 text-white font-semibold rounded-lg hover:bg-green-600 transition-colors duration-300 w-full md:w-auto"
              >
                Save All
              </button>
              <button
                onClick={handleClose}
                className="px-4 py-2 bg-gray-300 text-gray-700 font-semibold rounded-lg hover:bg-gray-400 transition-colors duration-300 w-full md:w-auto"
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default PrepNotesPopup;
