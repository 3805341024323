import axios from 'axios';
import { BackendApi } from './apiConfig/ApiFormatter';

export const JourneyStatusCheck = async (): Promise<any> => {
    const apiUrl = BackendApi() + "checkJourneyStatus";
    try {
        const response = await axios.get(apiUrl, {
            headers: {
                'Authorization': `${localStorage.getItem('AccessToken')}`
            }
        });

        return response.data.data; // Return the response data directly
    } catch (error) {
        console.error('Error fetching user questionnaire:', error);
        throw error; // Rethrow error to handle it in the component
    }
};

export const PremiumJourneyStatusCheck = async (): Promise<any> => {
    const apiUrl = BackendApi() + "getPremiumJourneyStatus";
    try {
        const response = await axios.get(apiUrl, {
            headers: {
                'Authorization': `${localStorage.getItem('AccessToken')}`
            }
        });
        // Correctly accessing the data from response
        if (response && response.data) {
            return response.data; // Return the data directly as it contains the 'journey_status'
        } else {
            console.error("Unexpected API response structure:", response);
            return null; // Return null if the structure is not as expected
        }
    } catch (error) {
        console.error('Error fetching premium journey status:', error);
        throw error; // Rethrow error to handle it in the component
    }
};

export const addPcjMetaToUser = async (attempt: number, version: number, reason: string): Promise<any> => {
    const apiUrl = `${BackendApi()}addPcjMetaToUser`;
    const idToken = localStorage.getItem('AccessToken');
    const payload = {
        attempt: attempt,
        version: version,
        reason: reason
    };
 
    try {
        const response = await fetch(apiUrl, {
            method: 'PUT', // HTTP method
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${idToken}`, // Bearer token for authorization
            },
            body: JSON.stringify(payload) // Convert payload to JSON
        });
 
        // Check if the response is ok (status code in the range 200-299)
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
       
        const data = await response.json();
        return data;
       
    } catch (error) {
        console.error('Error adding pcj_meta to user:', error);
        throw error;  // rethrow the error
    }
};

