import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { ApiResponse } from './JobList'
import Filter from '../../assets/img/Jobs/FilterImage.svg'
import axios from 'axios'
import { BackendApi } from '../../api/apiConfig/ApiFormatter'

interface JobFilterProps {
  onFilterChange: (companyFilters: string[]) => void
}

const JobFilter: React.FC<JobFilterProps> = ({ onFilterChange }) => {
  const [isFilterVisible, setIsFilterVisible] = useState(false)
  const [companies, setCompanies] = useState<string[]>([])
  const [selectedCompanies, setSelectedCompanies] = useState<Set<string>>(
    new Set()
  )
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const toggleFilter = () => setIsFilterVisible(!isFilterVisible)

  useEffect(() => {
    const fetchCompanies = async () => {
      const cachedCompanies = localStorage.getItem('uniqueCompanies')
      if (cachedCompanies) {
        setCompanies(JSON.parse(cachedCompanies))
        return
      }

      try {
        const response = await axios.get<ApiResponse>(
          `${BackendApi()}jobs/search`
        )
        const companyList = response.data.data.company
        setCompanies(companyList)
        localStorage.setItem('uniqueCompanies', JSON.stringify(companyList))
      } catch (error) {
        console.error('Error fetching companies:', error)
      }
    }
    fetchCompanies()
  }, [])

  const handleCheckboxChange = (company: string) => {
    const newSelectedCompanies = new Set(selectedCompanies)
    if (newSelectedCompanies.has(company)) {
      newSelectedCompanies.delete(company)
    } else {
      newSelectedCompanies.add(company)
    }
    setSelectedCompanies(newSelectedCompanies)
    onFilterChange(Array.from(newSelectedCompanies))
  }

  const clearAllSelections = () => {
    setSelectedCompanies(new Set())
    onFilterChange([])
  }

  const filterContent = (
    <div
      className={`p-3 border border-gray-200 rounded-lg flex flex-col gap-4 bg-white ${
        isMobile
          ? 'w-full h-full'
          : 'w-64 fixed top-30 left-6 overflow-y-auto max-h-[calc(100vh-7.2rem)]'
      }`}
    >
      <div className={`${isMobile ? 'flex-grow overflow-y-auto' : ''}`}>
        <div className="flex flex-col gap-2">
          <h4 className="text-base font-semibold">COMPANY NAME</h4>
          {companies.map((company) => (
            <label key={company} className="flex items-center gap-2">
              <input
                type="checkbox"
                checked={selectedCompanies.has(company)}
                onChange={() => handleCheckboxChange(company)}
                className="form-checkbox h-4 w-4 text-blue-600"
              />
              <span>{company}</span>
            </label>
          ))}
        </div>
      </div>
      <div
        className={`flex justify-between items-center border-t border-gray-600 pt-2 ${
          isMobile
            ? 'sticky bottom-0 bg-white py-3'
            : 'sticky bottom-0 bg-white py-2'
        }`}
      >
        <button
          onClick={clearAllSelections}
          className="text-gray-600 text-sm font-semibold"
        >
          Clear All
        </button>
      </div>
    </div>
  )

  return (
    <>
      {!isMobile && filterContent}
      {isMobile && (
        <>
          <div className="fixed top-44 left-0 right-0 bg-white z-10 px-4 pb-4">
            <div className="flex justify-end">
              <button
                onClick={toggleFilter}
                className="bg-white p-2 rounded-lg flex items-center gap-2 shadow-md text-lg font-semibold"
              >
                <span className="mr-1">Filter ({selectedCompanies.size})</span>
                <img src={Filter} alt="Filter" className="w-4 h-4" />
              </button>
            </div>
            {isFilterVisible && (
              <div
                className="fixed inset-0 bg-black bg-opacity-50 z-20"
                onClick={toggleFilter}
              ></div>
            )}
            {isFilterVisible && (
              <div className="absolute top-14 right-4 w-64 bg-white shadow-md rounded-lg overflow-y-auto max-h-96 z-30">
                {filterContent}
              </div>
            )}
          </div>
        </>
      )}
    </>
  )
}

export default JobFilter
