import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CreateNavbar from '../components/Navbar/Navbar';
import messageImage from '../assets/img/PremiumInterim/subscriptionMessage.svg';
import trainingIcon from '../assets/img/PremiumInterim/actionButton/training-icon.svg';
import counsellingIcon from '../assets/img/PremiumInterim/actionButton/counselling-icon.svg';
import exploreIcon from '../assets/img/PremiumInterim/actionButton/explore-icon.svg';
import journeyIcon from '../assets/img/PremiumInterim/actionButton/journey-icon.svg';
import mobiledoorImage from '../assets/img/PremiumInterim/mobileViewDoor.svg';
import mobilemessageImage from '../assets/img/PremiumInterim/mobileViewMessage.svg';
import { SnackbarUi } from '../components/Snackbar/SnackbarUi'; // Import your Snackbar component
import { FetchPremiumJourney } from '../api/FetchHtmlJourney';
import { PremiumJourneyStatusCheck } from '../api/JourneyStatusCheck';
import { storeUserData } from '../components/common/StorageUtil';
import { isUserTokenValid } from '../components/common/ValidateToken';
import { fetchUserDetails } from '../api/UserMetadata';

const SkillJourneyPage: React.FC = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [showScroll, setShowScroll] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [backendMessage, setBackendMessage] = useState('');
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const navigate = useNavigate();
  const [pcjStatus,setpcjStatus]=useState(""); 
  const [previousPublishedPCJ,setpreviousPublishedPCJ]=useState(0); 


  
  // Detect screen size to switch between mobile and desktop views
  useEffect(() => {
    fetchUserData();
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Set the breakpoint for mobile view
    };

    handleResize(); // Initial check on component mount
    window.addEventListener('resize', handleResize); // Add resize listener

    return () => window.removeEventListener('resize', handleResize); // Cleanup on component unmount
  }, []);

  // Scroll to top functionality
  useEffect(() => {
    const handleScroll = () => {
      setShowScroll(window.scrollY > 300); // Show button when scrolled 300px
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  const fetchUserData = async () => {
    const email:any=localStorage.getItem('Email');
    const data:any=await fetchUserDetails(email,navigate);
    setpcjStatus(data.PublishedJourney.status)
    setpreviousPublishedPCJ(data.PublishedJourney.version)
  };
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Main useEffect for initializing the page and handling the journey logic
  useEffect(() => {
    const initialize = async () => {
      const isValid = await isUserTokenValid();
      if (!isValid) {
        navigate('/');
        return;
      }
      const journeyExistsInStorage = sessionStorage.getItem('journeyExist') === 'true';

      if (!journeyExistsInStorage) {
        startPolling();
      } else {
        await fetchJourneyData(); // Fetch immediately if already exists
      }
    };

    if (!['Premium', 'Counselor'].includes(localStorage.getItem("userLevel") || '')) navigate('*', { state: { redirect: 'Premium-journey-page-unauthorised' } });

    const fetchJourneyData = async () => {
      const journeyCheck = await PremiumJourneyStatusCheck();
      if (journeyCheck && [ 'failed','published'].includes(journeyCheck.journey_status)) {
        stopPolling();
        if (journeyCheck.journey_status === 'published') {
          await FetchPremiumJourney();
          storeUserData('journeyExist', true);
          navigate('/premiumjourney'); // Navigate on success
          setBackendMessage('Journey completed successfully.');
          setIsSnackbarOpen(true);
        } else {
          setBackendMessage('Failed to complete the journey.');
          setIsSnackbarOpen(true);
        }
      }
    };

    const startPolling = () => {
      stopPolling(); // Ensure no previous intervals are running
      intervalRef.current = setInterval(fetchJourneyData, 5000);
    };

    const stopPolling = () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };

    initialize();

    return () => {
      stopPolling(); // Clear interval on component unmount
    };
  }, [navigate]);

  // Mobile Layout
  const MobileLayout = () => (
    <>
      {/* Navbar section */}
      <div className="flex overflow-y-auto flex-col">
        <CreateNavbar page="premiumJourney" />
      </div>

      {/* Main content section, centered with max width */}
      <div className="max-w-screen-lg mx-auto my-20 px-4">
        {/* Door image section */}
        <div
          className="flex justify-center items-center h-60 bg-cover bg-center bg-no-repeat"
          style={{ backgroundImage: `url(${mobiledoorImage})` }}
        >
          {/* Door Image section without the image inside */}
        </div>

        {/* Message Image: Positioned below the door image */}
        <img
          src={mobilemessageImage}
          alt="Message Image"
          className="w-full h-auto object-cover"
        />

        {/* Action button container for mobile with single box layout */}
        <div className="bg-white rounded-xl p-4 mt-6 grid grid-cols-2 gap-0 border border-transparent">
          {/* Trainings Button */}
          <button
            onClick={() => navigate('/trainings')}
            className="flex flex-col items-center justify-center py-4 border-b border-r border-gray-300"
          >
            <img src={trainingIcon} alt="Trainings" className="w-12.5 h-12.5 mb-4" />
            <span className="text-black-500 text-center underline text-lg">Trainings</span>
          </button>

          {/* Explore Opportunities Button */}
          <button
            onClick={() => navigate('/exploreopportunities')}
            className="flex flex-col items-center justify-center py-4 border-b border-gray-300"
          >
            <img src={exploreIcon} alt="Explore Opportunities" className="w-12.5 h-12.5 mb-4" /> {/* Increased margin-bottom */}
            <span className="text-black-500 text-center underline text-lg leading-4">Explore Opportunities</span>
          </button>

          {/* Counselling Button */}
          <button
            onClick={() => navigate('/counselling')}
            className="flex flex-col items-center justify-center py-4 border-r border-gray-300"
          >
            <img src={counsellingIcon} alt="Counselling" className="w-12.5 h-12.5 mb-4" />
            <span className="text-black-500 text-center underline text-lg">Counselling</span>
          </button>

          {/* Standard Journey Button */}
          <button
            onClick={() => navigate('/myjourney')}
            className="flex flex-col items-center justify-center py-4"
          >
            <img src={journeyIcon} alt="Standard Journey" className="w-12.5 h-12.5 mb-4" />
            <span className="text-black-500 text-center underline text-lg">Standard Journey</span>
          </button>
        </div>
        {
         pcjStatus==="published"  &&
          <div className="flex justify-center items-center pt-1">
            <button
              onClick={() => navigate('/premiumJourney',{ state: { pcj: previousPublishedPCJ } })}
              className="flex flex-row items-center"
            >
              <span className="text-black font-sans font-semibold text-sx">View my previous</span>
              <span className="text-[#0778B6] font-sans font-semibold text-sx ml-1">Premium Journey</span>
            </button>
          </div>    
        }
      </div>
    </>
  );

  // Desktop Layout
  const DesktopLayout = () => (
    <>
      {/* Navbar section */}
      <div className="flex overflow-y-auto flex-col">
        <CreateNavbar page="about" />
      </div>

      {/* Main content section, centered with max width */}
      <div className="max-w-screen-lg mx-auto my-10">
        {/* Main content section with door and message */}
        <div
          className="relative flex justify-start items-center h-[365px] bg-cover bg-center bg-no-repeat"
          style={{ backgroundImage: `url(https://i.imgur.com/RoU1oco.png)` }}
        >
          {/* Overlay the message SVG, positioned on the left */}
          <img src={messageImage} alt="Subscription Message" className="absolute inset-y-0 left-0 w-fill h-full object-fill" />
        </div>

        {/* Action button container styled to match uploaded design */}
        <div className="flex justify-center mt-8">
          <div className="bg-white rounded-xl shadow-[0_4px_15px_rgba(0,0,0,0.25)] p-4 flex items-center gap-4">
            {/* First button with icon */}
            <div className="px-4 flex items-center">
              <img src={trainingIcon} alt="Trainings" className="w-12 h-12 mr-2" />
              <button onClick={() => navigate('/trainings')} className="bg-transparent text-black-500 py-2 px-4 rounded hover:underline transition">
                Trainings
              </button>
            </div>

            {/* Second button with vertical line and icon */}
            <div className="border-l border-gray-400 px-4 flex items-center">
              <img src={counsellingIcon} alt="Counselling" className="w-12 h-12 mr-2" />
              <button onClick={() => navigate('/counselling')} className="bg-transparent text-black-500 py-2 px-4 rounded hover:underline transition">
                Counselling
              </button>
            </div>

            {/* Third button with vertical line and icon */}
            <div className="border-l border-gray-400 px-4 flex items-center">
              <img src={exploreIcon} alt="Explore Opportunities" className="w-12 h-12 mr-2" />
              <button onClick={() => navigate('/exploreopportunities')} className="bg-transparent text-black-500 py-2 px-4 rounded hover:underline transition">
                Explore Opportunities
              </button>
            </div>

            {/* Fourth button with vertical line and icon */}
            <div className="border-l border-gray-400 px-4 flex items-center">
              <img src={journeyIcon} alt="Standard Journey" className="w-12 h-12 mr-2" />
              <button onClick={() => navigate('/myjourney')} className="bg-transparent text-black-500 py-2 px-4 rounded hover:underline transition">
                Standard Journey
              </button>
            </div>
          </div>
        </div>
        {
         pcjStatus==="published" &&
          <div className="flex justify-center hover:underline items-center pt-5">
            <button
              onClick={() => navigate('/premiumJourney',{ state: { pcj: previousPublishedPCJ } })}
              className="flex flex-row items-center"
            >
              <span className="text-black font-sans font-medium text-sx">View my previous</span>
              <span className="text-[#0778B6] font-sans font-medium text-sx ml-1">Premium Journey</span>
            </button>
          </div>    
        }
      </div>
    </>
  );

  // Render Mobile or Desktop layout based on screen size
  return (
    <>
      {isMobile ? <MobileLayout /> : <DesktopLayout />}
      {showScroll && (
        <button
          onClick={scrollToTop}
          className="fixed bottom-5 right-5 p-3 bg-blue-500 text-white rounded-full shadow-lg hover:bg-blue-600 transition"
        >
          ↑
        </button>
      )}

      <SnackbarUi
        isSnackbarOpen={isSnackbarOpen}
        setIsSnackbarOpen={setIsSnackbarOpen}
        backendMessage={backendMessage}
      />

    </>
  );
};

export default SkillJourneyPage;
