import React, { useState, useRef, useLayoutEffect } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import IconButton from '@mui/material/IconButton'

export interface Job {
  company: string
  job_id: string
  position: string
  role: string
  location: string
  experience: string
  details: string
  responsibilities: string
  qualifications: string
  must_have_skills: string
  job_url: string
}

const ExpandableText: React.FC<{ text: string; label?: string }> = ({
  text,
  label,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [needsExpansion, setNeedsExpansion] = useState(false)
  const [lineHeight, setLineHeight] = useState(0)
  const textRef = useRef<HTMLDivElement>(null)
  const fullTextRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    if (textRef.current && fullTextRef.current) {
      const computedStyle = window.getComputedStyle(textRef.current);
      const lineHeightValue = parseInt(computedStyle.lineHeight);
      setLineHeight(lineHeightValue);

      const isOverflowing = fullTextRef.current.scrollHeight > lineHeightValue;
      setNeedsExpansion(isOverflowing);

      if (!isOverflowing) {
        textRef.current.style.height = `${lineHeightValue}px`;
      }
    }
  }, [text]);

  return (
    <div className="text-md text-gray-600 relative">
      <div
        ref={fullTextRef}
        className="absolute opacity-0 pointer-events-none"
        style={{ width: textRef.current?.clientWidth }}
      >
        {label && (
          <span className="font-semibold text-gray-800">{label}: </span>
        )}
        {text}
      </div>

      <div 
        ref={textRef} 
        className={`relative ${!isExpanded && needsExpansion ? 'h-12' : ''}`}
      >
        <div className={isExpanded ? '' : (needsExpansion ? 'line-clamp-2 pr-8' : '')}>
          {label && (
            <span className="font-semibold text-gray-800">{label}: </span>
          )}
          {text}
        </div>

        {needsExpansion && !isExpanded && (
          <div className="absolute right-0 bottom-0 inline-flex items-center bg-gradient-to-l from-white via-white pl-2">
            <IconButton
              onClick={() => setIsExpanded(true)}
              size="small"
              className="p-0"
            >
              <ExpandMoreIcon fontSize="small" />
            </IconButton>
          </div>
        )}
      </div>

      {needsExpansion && isExpanded && (
        <div className="mt-1 flex justify-end">
          <IconButton
            onClick={() => setIsExpanded(false)}
            size="small"
            className="p-0"
          >
            <ExpandLessIcon fontSize="small" />
          </IconButton>
        </div>
      )}
    </div>
  );
};

const JobCard: React.FC<{ job: Job }> = ({ job }) => {
  const hasContent = (value: any) => {
    return (
      typeof value === 'string' &&
      value.trim() !== '' &&
      !['Not Available', 'Not Specified', 'Not available', 'not available', 'Not specified', 'not specified'].includes(value.trim())
    );
  };

  return (
    <div className="bg-white p-4 md:p-6 rounded-lg shadow-md border border-gray-200 flex flex-col gap-3 md:gap-4">
      <div className="flex flex-row items-start md:items-center gap-2 md:gap-4">
        {/* <img
          src={`/jobs/${job.company.toLowerCase()}.svg`}
          alt={`${job.company} logo`}
          className="h-6 md:h-8 min-w-[30px] md:min-w-[40px]"
        /> */}
        <div className="flex flex-col md:flex-row items-start md:items-center gap-1 md:gap-2 w-full">
          <div className="flex flex-row items-center gap-1">
            <span className="text-sm md:text-base font-medium text-gray-800">
              {job.company}
            </span>
            {hasContent(job.location) && (
              <span className="text-xs md:text-sm text-gray-500 flex items-center gap-1">
                <LocationOnIcon fontSize="small" />
                {job.location}
              </span>
            )}
          </div>
          {hasContent(job.job_id) && (
            <span className="text-xs md:text-sm text-gray-500 mt-1 md:mt-0">
              ID: {job.job_id}
            </span>
          )}
        </div>
      </div>

      <div className="border-t border-gray-200 w-full" />

      <h3 className="text-lg md:text-xl font-semibold text-gray-800">
        {job.position}
      </h3>

      {hasContent(job.responsibilities) && (
        <ExpandableText text={job.responsibilities} label="Responsibilities" />
      )}

      {!hasContent(job.responsibilities) && hasContent(job.details) && (
        <ExpandableText text={job.details} label="Details" />
      )}

      {hasContent(job.experience) && (
        <ExpandableText text={job.experience} label="Experience" />
      )}

      {hasContent(job.qualifications) && (
        <ExpandableText text={job.qualifications} label="Qualifications" />
      )}

      <div className="flex flex-row justify-between items-start md:items-center gap-2 mt-2">
        <div className="flex flex-col gap-2">
          {hasContent(job.role) && (
            <ExpandableText text={job.role} label="Category" />
          )}
          {hasContent(job.must_have_skills) && (
            <ExpandableText text={job.must_have_skills} label="Skills" />
          )}
        </div>
        <a
          href={job.job_url || '#'}
          target="_blank"
          rel="noopener noreferrer"
          className="bg-[#0778B6] text-white px-3 py-2 rounded hover:bg-blue-700 transition duration-300 text-sm md:text-base whitespace-nowrap"
        >
          APPLY NOW
        </a>
      </div>
    </div>
  )
};

export default JobCard;