import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LaunchIcon from '@mui/icons-material/Launch';
import { checkIfCounselor } from '../../api/Counsellor';
import { generatePremiumJourney } from '../../api/UserMetadata';
import { SnackbarUi } from '../Snackbar/SnackbarUi';
import CloseIcon from '@mui/icons-material/Close'
import { addPcjMetaToUser } from '../../api/JourneyStatusCheck';

interface ConvertJourneyProps {
  standardJourney: any[]
  journeyPart: number
  setJourneyPart: (index: number) => void
  isIframe: boolean
  banners: string[]
  bannersMobile: string[]
  pcj_attempt:number
  isPremium: boolean
  pcjStatus:string
  version: number;
}

const ConvertJourney: React.FC<ConvertJourneyProps> = ({
  standardJourney,
  journeyPart,
  setJourneyPart,
  isIframe,
  banners,
  bannersMobile,
  pcj_attempt,
  isPremium,
  pcjStatus,
  version,
}) => {
  const refs = useRef<any[]>([]);
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isCounselor, setIsCounselor] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [showModal, setShowModal] = useState(false); // Modal state
  const [inputValue, setInputValue] = useState(''); 
  const [snackbarMessage, setSnackbarMessage] = useState('');

// --------code to change steaper location as per the scroll location but it breaks the steaper scroll logic and hangs the system -------
  // const handleIntersect = useCallback(
  //     (entries: IntersectionObserverEntry[]) => {
  //         entries.forEach((entry) => {
  //             if (entry.isIntersecting) {
  //                 const index = refs.current.indexOf(entry.target);
  //                 if (index !== -1 && index !== journeyPart) {
  //                     setJourneyPart(index);
  //                 }
  //             }
  //         });
  //     },
  //     [journeyPart, setJourneyPart]
  // );
  // useEffect(() => {
  //     const observer = new IntersectionObserver(handleIntersect, {
  //         threshold: 0.7, // Adjust the threshold as needed
  //     });
  //     refs.current.forEach((ref) => {
  //         if (ref) observer.observe(ref);
  //     });
  //     return () => {
  //         refs.current.forEach((ref) => {
  //             if (ref) observer.unobserve(ref);
  //         });
  //     };
  // }, [handleIntersect]);
  // ---------------------------------------------------------------------------------------------

  // Update isMobile state on resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  // Scroll into view for the current journey part
  useEffect(() => {
    if (refs.current[journeyPart]) {
      setTimeout(() => {
        refs.current[journeyPart].scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
      }, 500)
    }
  }, [journeyPart, standardJourney])

  const generateJourney: any= async () => {
    try {
      const journey_status = await generatePremiumJourney();
      if (journey_status) {
        navigate('/premiumjourneyloading')
      }
    } catch (error) {
      console.error('Error checking counselor status:', error);
    }
  };

  const handleCreateJourney = async () => {
    try {
        await addPcjMetaToUser(pcj_attempt, version, inputValue);
        const journey_status = await generatePremiumJourney();

        if (journey_status) {
            navigate('/premiumjourneyloading');
        }
    } catch (error) {
      console.error('Error creating journey:', error);
    }
  };

  // Check if user is a counselor
  const fetchCounselorStatus = async () => {
    try {
      const counselorStatus = await checkIfCounselor(navigate);
      if (counselorStatus?.is_counselor) {
        setIsCounselor(true);
      }
    } catch (error) {
      console.error('Error checking counselor status:', error);
    }
  };

  useEffect(() => {
    fetchCounselorStatus();
  }, []);

  const alternateCJOptions = standardJourney
    .find((section) => section.Alternate_CJ_Options)
    ?.Alternate_CJ_Options?.trim()

  return (
    <>
      {standardJourney.map((section: any, index: number) => {
        const hasData =
          section.title ||
          section.assessment ||
          section.milestones?.length ||
          section.actionable?.length ||
          section.potentialOutcome

        return (
          hasData && (
            <div
              key={index}
              ref={!isIframe ? (el) => (refs.current[index] = el) : null}
              className="px-1 py-2"
            >
              {section.title && (
                <div className="text-lg font-bold text-cyan-700">
                  {section.title.replace(/^Title for /, '')}
                </div>
              )}
              {section.assessment && (
                <p className="flex text-sm py-1 font-sans">
                  <span>
                    <div className="font-semibold text-base">
                      Current Assessment:{' '}
                    </div>
                    {section.assessment}
                  </span>
                </p>
              )}
              {section.milestones?.length > 0 && (
                <p className="flex text-sm py-1 font-sans">
                  <span>
                    <div className="font-semibold text-base">Plan: </div>
                    <ul className="list-decimal list-inside">
                      {section.milestones.map(
                        (milestone: string, idx: number) => (
                          <li key={idx}>{milestone}</li>
                        )
                      )}
                    </ul>
                  </span>
                </p>
              )}
              {section.actionable?.length > 0 && (
                <p className="flex text-sm py-1 font-sans">
                  <span>
                    <div className="font-semibold text-base">Actionables: </div>
                    {section.actionable.map((links: any, key: number) => (
                      <div key={key} className="flex flex-col">
                        {links.description && links.url && (
                          <>
                            <div className="flex">
                              <span className="mr-2">{key + 1}.</span>
                              <span>{links.description}</span>
                            </div>
                            <div className="ml-4">
                              <span className="text-blue-500 cursor-pointer text-sm">
                                <a
                                  href={links.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Click here{' '}
                                  <LaunchIcon
                                    style={{ fontSize: '0.875rem' }}
                                  />
                                </a>
                              </span>
                            </div>
                          </>
                        )}
                      </div>
                    ))}
                  </span>
                </p>
              )}
              {section.potentialOutcome && (
                <p className="flex text-sm py-1 font-sans">
                  <span>
                    <div className="font-semibold text-base">
                      Potential Outcome:{' '}
                    </div>
                    {section.potentialOutcome}
                  </span>
                </p>
              )}
              {/* Display unique banner image after each potential outcome with navigation */}
              {(isMobile ? bannersMobile[index] : banners[index]) && (
                <img
                  src={isMobile ? bannersMobile[index] : banners[index]}
                  alt={`Banner ${index + 1}`}
                  className={`mt-4 rounded-lg ${(!isCounselor || !isIframe) ? 'cursor-pointer' : ''}`}
                  onClick={async () => {
                    if (!(isIframe)) {
                      if (index === 0 || index === 2 || index === 4) {
                        if(pcj_attempt==3){
                          navigate('/premiumAdvantages');
                        } else if (pcj_attempt < 3 && isPremium && ( pcjStatus === "initiated" || pcjStatus === "generating" || pcjStatus === "completed")) {
                          navigate('/premiumjourneyloading')
                        } else if (pcj_attempt < 3 && isPremium && ( pcjStatus === "published")) {
                          setShowModal(true); 
                        } else if (!isPremium) {
                          navigate('/premiumAdvantages');
                        }else{
                          navigate('/premiumjourneyloading');
                        }
                      } else if (index === 1) {
                        navigate('/counselling');
                      } else if (index === 3) {
                        const token =localStorage.getItem('LMSToken')
                        window.location.href = "https://trainings.skilljourney.in/?"+`ssotoken=${token}`
                      }
                    }
                  }}
                />
              )}
            </div>
          )
        );
      })}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 px-8 sm:px-6"> 
          <div className="relative bg-white p-6 rounded-lg shadow-lg max-w-xl w-full sm:w-auto mx-auto">
            <CloseIcon fontSize="small" className="absolute top-2 right-2 cursor-pointer text-gray-500" onClick={() => setShowModal(false)} />
            <h2 className="text-sm font-normal my-2">
            You have an existing Premium Journey. Are you sure you want to create another one? If yes, please provide a reason below to improve our process. Thank You.           
            </h2>
            <textarea
              placeholder="Enter your reason here"
              className="w-full p-3 border border-gray-300 rounded text-sm focus:outline-none focus:ring-2"
              style={{ height: '100px', resize: 'none' }} // Increased height, font size, and disabled resizing
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
            <p className='text-xs text-gray-500'>You get a maximum of 3 attempts before you are asked to pay again.</p>
            {inputValue && inputValue.length < 5 && (
              <p className="text-red-500 text-xs mb-3">Please enter at least 5 characters.</p>
            )}
            <div className="flex justify-end mt-3">
              <button
                onClick={handleCreateJourney}
                className="px-5 py-2 bg-[#0778B6] text-white text-sm font-semibold rounded-full hover:bg-[#055a8c] transition-colors duration-200"
                disabled={inputValue.length < 5}
              >
                Create Journey
              </button>
            </div>
          </div>
        </div>
      )}
      {alternateCJOptions && alternateCJOptions.length > 0 && (
        <div className="px-1 py-2">
          <div className="text-lg font-bold text-cyan-700">
            Alternate Career Journey Options:
          </div>
          <ul className="text-sm py-1 font-sans">
            {alternateCJOptions
              .replace(/\*/g, '')
              .split('\n')
              .map((option: string, idx: number) => (
                <li key={idx} className="pb-1">
                  {option}
                </li>
              ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default ConvertJourney;
